<template>
  <div
    v-if="data && !!data.long"
    class="delivery-info"
    :style="`color: ${data.color}`"
  >
    <div class="delivery-infoWrap lever-txt working-day">
      <img v-if="data.icon" :src="`${data.icon}`" alt="" />
      <font-awesome-icon v-else-if="icon" :icon="icon" />
      <label>{{ label }} </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeliveryTime",
  props: {
    data: { required: true },
    display_type: { type: String, required: false },
    icon: { type: String, required: false },
  },
  computed: {
    label() {
      if (this.display_type) {
        const display_type = this.display_type;
        return this.data[display_type];
      } else {
        return this.data.long;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-infoWrap {
  display: flex;
  margin-bottom: 5px;
  align-items: flex-start;
  justify-content: left;
  width: 100%;
  @include phone-xl {
    align-items: center;
    justify-content: unset;
    margin-top: 6px;
  }
  img {
    width: 18px;
    margin-right: 5px;
    position: relative;
    top: 2px;

    @include phone-xl {
      width: 18px;
      top: 6px;
    }
  }
  svg {
    margin-right: 5px;
    position: relative;
    top: 2px;
    @include phone-xl {
      top: 0px;
    }
  }
  label {
    margin-bottom: 0;
    word-break: break-word;
    line-height: 17px;
    font-weight: 500;

    @include desktop-md {
      font-size: 14px;
    }

    // @include phone-xl {
    //   font-size: 10px;
    // }
  }
}
</style>
