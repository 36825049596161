<template>
  <div
    class="product-card"
    v-match-heights="{ el: ['.productInfo', '.per-unit'] }"
  >
    <b-modal
      ref="product-delete-wish"
      id="product-delete-wish"
      class="product-modal-inner"
      content-class="defaul-modal"
      hide-footer
      centered
      size="lg"
    >
      <template #modal-title class="bold"
        >{{ $t("remove_product_wishlist") }}
      </template>
      <div class="modal-text d-block border-bottom">
        <p>
          {{ $t("sure_remove_product_wishlist") }}
        </p>
      </div>
      <div class="modal-btn-box pt-20">
        <b-button class="btn-success" block @click="clickOk">Ok</b-button>
        <b-button class="btn-success btn-outline" block @click="clickCancel">{{
          $t("cancel")
        }}</b-button>
      </div>
    </b-modal>
    <div class="card-body custom-card-body" v-if="product != null">
      <div class="custom-card-body-link">
        <b-link
          @click="showPopup"
          class="wishlist-icon-card"
          :class="{ danger: isInWishlist }"
          v-if="isInWishlist"
        >
          <b-icon icon="heart-fill" class="active"></b-icon>
        </b-link>
        <b-link
          @click="updateWish"
          class="wishlist-icon-card"
          :class="{ danger: isInWishlist }"
          v-else
        >
          <b-icon icon="heart" class="active"></b-icon>
        </b-link>
        <div class="product-img">
          <router-link
            :to="`/${product.url_key}/`"
            @click.native="$root.$emit('closeSearchModal')"
          >
            <div
              class="product-tag"
              v-if="
                product.cashback_promotion ||
                (product.labels && product.labels.length)
              "
            >
              <div
                class="tag discount-txt-wrap"
                v-if="product.cashback_promotion"
              >
                <span class="discount-txt"
                  >&euro;{{
                    product.cashback_promotion.amount.value + " cashback"
                  }}
                </span>
              </div>
              <div
                class="product-tag-wrap"
                v-if="product.labels && product.labels.length"
              >
                <div
                  class="product-tag-single"
                  v-for="(label, idx) in product.labels"
                  :key="idx"
                >
                  <div v-if="label.category.type === 'image'" class="img-label">
                    <div
                      class="tag-label"
                      :class="label.category.position"
                      :style="
                        `width:${label.category.size}px;
                      height:${label.category.size}px;
                      background-image: url('` +
                        label.category.image_url +
                        `');
                      ${label.category.css};`
                      "
                    />
                  </div>

                  <div v-if="label.category.type === 'text'" class="text-label">
                    <div
                      class="tag-label"
                      :class="label.category.position"
                      :style="label.category.css"
                    >
                      {{ label.category.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <b-img
              :alt="product.name"
              :src="
                product.thumbnail.url
                  ? product.thumbnail.url
                  : product.thumbnail.medium
              "
            />
          </router-link>
        </div>
        <p
          class="offer-tag"
          :class="{
            'opacity-0':
              product.price_range.maximum_price.discount.percent_off == 0,
          }"
        >
          <b-link class="offer-link">
            -&nbsp;{{
              product.price_range.maximum_price.discount.percent_off.toFixed(0)
            }}%
          </b-link>
        </p>
        <b-card-text class="productInfo">
          <router-link
            :to="`/${product.url_key}/`"
            @click.native="$root.$emit('closeSearchModal')"
          >
            <span class="merk">{{ brand }}</span
            ><br />
            {{ product.name }}
          </router-link>
        </b-card-text>
        <div class="txt-div">
          <div class="shopping-cart-icon" v-if="!isHomePage">
            <span class="right">
              <b-link href="#" @click="addToCart">
                <font-awesome-icon
                  icon="shopping-cart"
                  style="color: #fff; font-size: 18px"
                />
              </b-link>
            </span>
          </div>

          <!-- Advance Price -->
          <span
            v-if="
              product.price_range.minimum_price.regular_price.value !=
              product.price_range.minimum_price.final_price.value
            "
            class="adv-price"
            >{{ $t("Adv_price") }}
            <span 
              v-if="
                product.price_range.minimum_price.regular_price.value !=
                product.price_range.minimum_price.final_price.value
              "
              class="strike-txt font-18"
              ><span class="font-12">
                {{
                  formatCurrency(
                    product.price_range.minimum_price.regular_price.value
                  )
                }}</span
              ></span
            >
          </span>
          <br />
          <p class="per-unit">
            <span
              v-if="
                product.__typename == 'BundleProduct' &&
                  product.price_range.maximum_price.final_price.value !=
                    product.price_range.minimum_price.final_price.value
              "
            >
              {{ $t("From") }}</span
            >
            <span class="strike-txt"
              ><strong>
                {{
                  formatCurrency(
                    product.price_range.minimum_price.final_price.value
                  )
                }}</strong
              >
              <span v-if="product.unit" class="font-18">
                {{ product.unit }}
              </span>
            </span>
          </p>
          <!-- <p class="stuck-info" v-if="product.stock_status === 'IN_STOCK'">
            <span>
              {{ $t("in_stock")
              }}--><!--//To be determined by HomingXl how to implement : {{ product.stock_qty }} {{ $t("pieces") }} --><!--
            </span>
          </p> -->
          <DeliveryTime
            :data="product.DeliveryTime"
            v-if="product.stock_status !== 'IN_STOCK'"
          />
          <p class="stuck-info stuck-size bold pb-15" v-else>
            <span
              v-if="
                product.stock_qty > product.stock_display_limit ||
                product.stock_display_limit == null
              "
            >
              {{ $t("in_stock")
              }}<!--//To be determined by HomingXl how to implement : {{ product.stock_qty }} {{ $t("pieces") }} -->
            </span>
            <span v-else>
              {{ $t("x_left_in_stock", { qty: product.stock_qty })
              }}<!--//To be determined by HomingXl how to implement : {{ product.stock_qty }} {{ $t("pieces") }} -->
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DeliveryTime from "@/esf_utrecht_bankxl/core/components/products/product-detail/DeliveryTime";

export default {
  name: "ProductCard",
  props: ["product"],
  data() {
    return {};
  },
  components: {
    DeliveryTime,
  },
  computed: {
    prod() {
      return this.product;
    },
    isInWishlist() {
      return this.$store.getters["wishlist/isProductInWishlist"](
        this.product.sku
      );
    },
    isHomePage() {
      return this.$route.name === "home";
    },
    brand() {
      var merk = "";
      this.product.information_attributes?.forEach((attribute) => {
        if (attribute.attribute_code == "merk") {
          merk = attribute.value;
          return;
        }
      });
      return merk;
    },
  },
  created() {},
  methods: {
    async addToCart() {
      if (this.product.__typename == "SimpleProduct") {
        const item =
          '{data: {sku: "' + this.product.sku + '", quantity:' + 1 + "}}  ";
        let retval = await this.$store.dispatch("cart/addToCart", {
          type: this.product.__typename,
          item: item,
        });

        if (retval == false) {
          const msg = {
            type: "danger",
            title: this.$t("shopping_basket"),
            text: this.$t("not_add_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        } else {
          const msg = {
            type: "success",
            title: this.$t("shopping_basket"),
            text: this.$t("added_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
      } else {
        this.$router.push(this.product.url_key + "/");
      }
    },
    updateWish() {
      if (this.isInWishlist) {
        this.$store.dispatch("wishlist/removeProductFromWishlist", {
          sku: this.product.sku,
        });
      } else {
        if (this.product.__typename == "SimpleProduct") {
          this.$store.dispatch("wishlist/addProductToWishlist", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          const childSku = this.$store.getters["product/getCurrentChildSku"];
          if (childSku == null) {
            const msg = {
              type: "danger",
              title: this.$t("wishlist error"),
              text: this.$t("wishlist_select_options"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            return;
            //this.$router.push("/" + this.product.url_key);
          } else {
            this.$store.dispatch("wishlist/addProductToWishlist", {
              sku: childSku,
              parentSku: this.product.sku,
            });
          }
        }

        const msg = {
          type: "success",
          text: this.$t("wishlist success"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount, false);
    },
    clickOk() {
      this.$bvModal.hide("product-delete-wish");
      this.updateWish();
    },
    clickCancel() {
      this.$bvModal.hide("product-delete-wish");
    },
    showPopup() {
      this.$refs["product-delete-wish"].show();
    },
  },
};
</script>

<style lang="scss" scoped>
.adv-price {
  font-size: 13px;
  color: #525252;
  font-weight: 400;
}
</style>
